<template>
    <b-overlay :show="loading">
    
        <b-card>
            <div class="d-flex justify-content-between align-items-center mb-2">
                <h3 class="mb-1">LPJ BOP</h3>
                <div v-if="bop.lpj == 1">
                    <b-badge variant="success">LPJ Sudah Disetor</b-badge>
                </div>
            </div>
            <header class="mb-2 d-flex justify-content-between align-items-center" >
                <b-button variant="info" @click.prevent="addSellingNote" v-if="isSales && bop.lpj == 0">
                    <feather-icon icon="PlusIcon"></feather-icon>
                    Tambah Rincian LPJ
                </b-button>
                <b-button v-if="isFinance && bop.appr_terima > 0" :disabled="sellingNotes.length < 1" :variant="sellingNotes.length > 0 ? 'outline-danger' : 'outline-secondary'" @click.prevent="printLPJ">
                    <feather-icon icon="PrinterIcon"></feather-icon>
                    Cetak LPJ
                </b-button>
            </header>
            <b-alert
                show
                fade
                class="mb-0"
                variant="primary" v-if="!addAction">
                <div class="alert-body">
                    LPJ BOP Kosong
                </div>
            </b-alert>
            <section v-if="addAction">
                <b-row class="mb-1">
                    <b-table
                        :fields="fields"
                        :items="sellingNotes"
                        hover
                        responsive
                        bordered
                        striped
                    >
                        <template #cell(no)="{index}">
                            {{index + 1}}
                        </template>
                        <template #cell(add)>
                            <b-button v-if="bop.lpj == 0" variant="primary" class="rounded-full" size="sm" @click.prevent="addList">
                                <feather-icon icon="PlusCircleIcon"></feather-icon>
                            </b-button>
                            <b-button v-else disabled>
                                <feather-icon icon="PlusCircleIcon"></feather-icon>
                            </b-button>
                        </template>
                        <template #cell(no_nota)="{item}">
                            <b-form-input v-if="isSales" v-model="item.no_nota" />
                            <span v-else>
                                {{item.no_nota}}
                            </span>
                        </template>
                        <template #cell(keterangan)="{item}">
                            <b-form-input v-if="isSales" v-model="item.keterangan"></b-form-input>
                            <p v-else >
                                {{item.keterangan}}
                            </p>
                        </template>
                        <template #cell(qty)="{item}">
                            <b-form-input v-if="isSales" v-model="item.qty" type="number"></b-form-input>
                            <p v-else >
                                {{item.qty}}
                            </p>
                        </template>
                        <template #cell(harga)="{item, index}">
                            <b-form-input v-if="isSales" v-model="item.harga" @keyup="doFormatRupiah(index)"></b-form-input>
                            <p v-else >
                                {{item.harga}}
                            </p>
                        </template>
                        <template #cell(action)="{index, item}">
                            <b-button v-if="index > 0 && bop.lpj == 0" size="sm" varian="danger" @click.prevent="remove(index, item)">
                                <feather-icon icon="XIcon"></feather-icon>
                            </b-button>
                            <b-button v-else disabled>
                                
                                <feather-icon icon="XIcon"></feather-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-row>
                <b-row class="justify-content-end">
                    <b-col cols="12" class="mb-2">
                        <b-button v-if="bop.lpj == 0 && isSales" variant="info" @click.prevent="saveNota">
                            <feather-icon class="mr-1" icon="SaveIcon"></feather-icon>
                            <span>Simpan</span>
                        </b-button>
                        <b-button variant="info" disabled v-else>
                            <feather-icon class="mr-1" icon="SaveIcon"></feather-icon>
                            <span>Simpan</span>
                        </b-button>
                    </b-col>
                    <b-col sm="12" md="6">
                        <div class="d-flex flex-column justify-content-end">
                            <table class="table table-bordered table-striped mb-2">
                                <tr>
                                    <td>Total Pengeluaran</td>
                                    <th>
                                        Rp {{ formatRupiah(total) }}
                                    </th>
                                </tr>
                                <tr>
                                    <td>Nominal</td>
                                    <th>
                                        Rp {{ formatRupiah(bop.nominal) }}
                                    </th>
                                </tr>
                                <tr v-if="isReimburse">
                                    <td>Reimburse</td>
                                    <th>
                                        Rp {{ formatRupiah(sisa) }}
                                    </th>
                                </tr>
                                <tr v-if="isPengembalian">
                                    <td>Pengembalian</td>
                                    <th>
                                        Rp {{ formatRupiah(sisa) }}
                                    </th>
                                </tr>
                            </table>
                        </div>
                        <div v-if="isSales" class="d-flex justify-content-end">
                            <b-button v-if="bop.lpj == 0" variant="info" @click.prevent="setorNota">                               <feather-icon class="mr-1" icon="CornerUpRightIcon"></feather-icon>
                                <span>Setor BOP</span>
                            </b-button>
                            <b-button v-else disabled>Sudah Disetor</b-button>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-card>
    </b-overlay>
</template>
<script>
import {BCard, BBadge, BOverlay, BAlert, BButton, BFormGroup, BFormInput, BRow, BCol, BTable} from 'bootstrap-vue'
const initValues = [
{
        ajuan_id: null,
        keterangan: 'Penginapan',
        nominal: 0,
        qty: 0,
        harga: 0,
        no_nota: null
    },
    {
        ajuan_id: null,
        keterangan: 'BBM',
        nominal: 0,
        qty: 0,
        harga: 0,
        no_nota: null
    },
    {
        ajuan_id: null,
        keterangan: 'Uang Makan',
        nominal: 0,
        qty: 0,
        harga: 0,
        no_nota: null
    },
    {
        ajuan_id: null,
        keterangan: 'Cuci Mobil',
        nominal: 0,
        qty: 0,
        harga: 0,
        no_nota: null
    }
]
export default {
    props: ['bop'],
    components: {
        BOverlay, BCard, BBadge, BAlert, BButton, BFormGroup, BFormInput, BRow, BCol, BTable
    },
    computed: {
        isReimburse() {
            return this.total > this.bop.nominal
        },
        isPengembalian() {
            return this.bop.nominal > this.total
        },
        sisa() {
            if(this.isReimburse) {
                return parseInt(this.total) - this.bop.nominal
            }
            return parseInt(this.bop.nominal) - parseInt(this.total)
        },
        total() {
            if(this.sellingNotes.length < 1) {
                return 0
            }

            return this.sellingNotes.reduce((total, list) => total += (parseInt( this.unFormatRupiah(list.harga) ) * parseInt(list.qty)), 0)
        }
    },
    data: () => ({
        loading: false,
        addAction: false,
        sellingNotes: [],
        ajuan: null,
        initValues,
        fields: [
            {key: 'add', label: '#'},
            {key: 'no_nota', label: 'No. Nota'},
            {key: 'keterangan', label: 'Keterangan'},
            {key: 'qty', label: 'Jumlah'},
            {key: 'harga', label: 'Harga'},
            {key: 'action', label: ''}
        ]
    }),
    watch: {
        bop(val) {
            if(val.appr_terima && val.appr_terima == 1) {
                this.getLaporan()
            }
        }
    },
    methods: {
        doFormatRupiah(index) {
            this.sellingNotes[index].harga = this.formatRupiah(this.sellingNotes[index].harga)
        },
        printLPJ() {
            this.$emit('printLPJ', true)
        },
        async remove(index, item) {
            if(!item.id) {
                this.sellingNotes.splice(index, 1)
            }
            else {
                await this.$store.dispatch('nota-bop/save', [{id: item.id, fungsi: 2}])
                this.getLaporan()
            }
        },
        async getLaporan() {
            this.sellingNotes = await this.$store.dispatch('nota-bop/getData', {
                ajuan_id: this.$route.params.id
            })
            if(this.sellingNotes.length > 0) {
                this.addAction = true
                this.sellingNotes.map(note => note.harga = this.formatRupiah(note.harga))
            }
        },
        addList() {
            this.sellingNotes.push({
                ajuan_id: null,
                keterangan: '-',
                nominal: null,
                qty: 0,
                harga: 0,
                no_nota: null
            })
        },
        addSellingNote() {
            this.sellingNotes = []
            this.addAction = true
            this.sellingNotes = initValues
        },
        setorNota() {
            this.$swal({
                title: "Setor LPJ ke Finance?",
                text: "Nominal Reimburse atau Pengembalian akan otomatis disetorkan ke Finance",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                cancelButtonText: "Batal",
                customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            })
            .then(async (res) => {
                if(res.value) {

                    try {
                        await this.$store.dispatch('bop/setorBOP', [{
                            id_ajuan_bop: this.bop.id
                        }])
                        this.displaySuccess({
                            message: 'BOP berhasil disetorkan'
                        })
                        setTimeout(async () => {
                            const params = {order: 'desc', sales_id: this.user.karyawan.id}
                            await this.$store.dispatch('bop/getData', params)
                            this.$emit('changeActiveTab', 'titleTab')
                        }, 1000)
    
                    }
                    catch(e) {
                        this.displayError(e)
                        return false
                    }
                }
            })
        },
        async saveNota() {
            const formValid = this.sellingNotes.some(list => list.qty > 0 && parseInt(list.harga) > 0 && list.keterangan != '')
            if(!formValid) {
                this.displayError({
                    message: 'Harap lengkapi data'
                })

                return false
            }

            let payloads = []
            this.sellingNotes.map(list => {
                let payload = {
                    ajuan_id: this.bop.id,
                    nominal: this.bop.nominal,
                    no_nota: list.no_nota,
                    qty: list.qty,
                    harga: this.unFormatRupiah(list.harga),
                    keterangan: list.keterangan
                }
                if(list.id) {
                    payload.id = list.id
                }
                payloads.push(payload)
            })

            try {
                this.loading = true
                await this.$store.dispatch('nota-bop/save', payloads)
                this.loading = false

                this.displaySuccess({
                    message: 'LPJ BOP berhasil dibuat!'
                })

                this.$emit('regetLaporan', true)
                
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        }
    },
    created() {
        if(this.isSales) {
            this.fields = [
                {key: 'add', label: '#'},
                {key: 'no_nota', label: 'No. Nota'},
                {key: 'keterangan', label: 'Keterangan'},
                {key: 'qty', label: 'Jumlah'},
                {key: 'harga', label: 'Harga'},
                {key: 'action', label: ''}
            ]
        }
        else {
            this.fields = [ 
                {key: 'no', label: 'No'},
                {key: 'no_nota', label: 'No. Nota'},
                {key: 'keterangan', label: 'Keterangan'},
                {key: 'qty', label: 'Jumlah'},
                {key: 'harga', label: 'Harga'}
            ]
        }
        const isAjuanSelesai = this.bop.appr_terima && this.bop.appr_terima == 1
        if(isAjuanSelesai) {
            this.getLaporan()
        }
    }
}
</script>